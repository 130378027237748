import React from 'react';
import MenuBoard from '../../../components/MenuBoard';
import MenuBoardSection from '../../../components/MenuBoardSection';
import LayoutMenuBoard from '../../../layouts/layout-menuboard';
import { ALACARTE, GOOD_STUFF, WEEKEND } from './../../../utils/items';

export default function MenuBoard2() {
	return (
		<LayoutMenuBoard>
			<MenuBoard>
				<MenuBoardSection {...GOOD_STUFF} />
				<MenuBoardSection {...ALACARTE} />
				<MenuBoardSection {...WEEKEND} />
			</MenuBoard>
		</LayoutMenuBoard>
	);
}
